import React from 'react'
import { Outlet } from 'react-router'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
import 'react-use-smooth-scroll/dist/index.css'

const Layout = () => {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />

        </>
    )
}

export default Layout