import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import '@coreui/coreui/dist/css/coreui.min.css'
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Lottie from 'react-lottie';
import loaderIcon from './Assets/images/json/loader.json';

function App() {
  const loaderOption = {
    loop: true,
    autoplay: true,
    animationData: loaderIcon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate page loading
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <div className='loaderMain'>
            <Lottie
              options={loaderOption}
              height={200}
              width={200}
            />
          </div>
        </>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
            </Route>
          </Routes>
        </BrowserRouter>
      )}

    </>
  );
}

export default App;
