import React from 'react';
import broadIcon1 from '../../Assets/images/broad-icon1.png'
import broadIcon2 from '../../Assets/images/broad-icon2.png'
import broadIcon3 from '../../Assets/images/broad-icon3.png'

const Services = () => {
    return (
        <>
            {/* Services Section Start */}
            <section className="broadBoxMain">
                <div className="container">
                    <div className="row">
                    <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className='singleBlog'>
                                <div className="singleBlogIn">
                                    <div className="imgBox">
                                        <img src={broadIcon1} alt="broadIcon1" width="120" height="120" />
                                    </div>
                                    <div className="txtBox">
                                        <span className="txtNumber">1200+</span>
                                        <h6>Live online</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className='singleBlog'>
                                <div className="singleBlogIn">
                                    <div className="imgBox">
                                        <img src={broadIcon2} alt="broadIcon2" width="120" height="120" />
                                    </div>
                                    <div className="txtBox">
                                        <span className="txtNumber">29038+</span>
                                        <h6>Active Member</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="singleBlog">
                                <div className='singleBlogIn'>
                                    <div className="imgBox">
                                        <img src={broadIcon3} alt="broadIcon3" width="120" height="120" />
                                    </div>
                                    <div className="txtBox">
                                        <span className="txtNumber">2000K+</span>
                                        <h6>Daily Reward</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Services Section End */}
        </>
    )
}

export default Services