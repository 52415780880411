import React from 'react'
import About from '../../Components/About/About'
import Banner from '../../Components/Banner/Banner'
import Faqs from '../../Components/Faqs/Faqs'
import OurNFT from '../../Components/OurNFT/OurNFT'
// import PdfDownload from '../../Components/PdfDownload/PdfDownload'
import Services from '../../Components/Services/Services'
const Home = () => {
    return (
        <>
            <Banner />
            <Services />
            <About />
            {/* <PdfDownload /> */}
            <OurNFT />
            <Faqs />
        </>
    )
}

export default Home