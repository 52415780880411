import React from 'react'
import { FaTwitter, FaFacebook, FaInstagram, FaReddit, FaYoutube, FaTelegram, FaMedium } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        // footer area start
        <footer>
            <div className="container">
                <div className="copyright_right">
                    <p>© 2023 <i className="icofont-heart"></i>  by <a href="#!">Has#fair</a></p>
                </div>
                <ul className="d-flex justify-content-center align-items-center">
                    <li><Link to="https://www.facebook.com/hashfairgames" title='Facebook' target="_blank" className="mx-2"><FaFacebook /></Link></li>
                    <li><Link to="https://www.instagram.com/hashfairgames/" title='Instagram' target="_blank" className="mx-2"><FaInstagram /></Link></li>
                    <li><Link to="https://twitter.com/Hashfair_Games" title='Twitter' target="_blank" className="mx-2"><FaTwitter /></Link></li>
                    <li><Link to="https://www.reddit.com/r/Hashfair/" title='FaReddit' target="_blank" className="mx-2"><FaReddit /></Link></li>
                    {/* <li><Link to="https://medium.com/@hashfairgames" title='Medium' target="_blank" className="mx-2"><FaMedium /></Link></li> */}
                    <li><Link to="https://www.youtube.com/channel/UCt_4P2eqk0Jsn5MNsWapqcA" title='Youtube' target="_blank" className="mx-2"><FaYoutube /></Link></li>
                    <li><Link to="https://t.me/HashFairGames" title='Telegram ' target="_blank" className="mx-2"><FaTelegram /></Link></li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer