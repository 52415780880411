import React from 'react';
// import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyLoad from 'react-lazyload';
import nftLvl1 from '../../Assets/images/nfts/json/hashfair-nft-lvl1.json'
import nftLvl2 from '../../Assets/images/nfts/json/hashfair-nft-lvl2.json'
import nftLvl3 from '../../Assets/images/nfts/json/hashfair-nft-lvl3.json'
import nftLvl4 from '../../Assets/images/nfts/json/hashfair-nft-lvl4.json'
import nftLvl5 from '../../Assets/images/nfts/json/hashfair-nft-lvl5.json'
import nftLvl6 from '../../Assets/images/nfts/json/hashfair-nft-lvl6.json'
import nftLvl7 from '../../Assets/images/nfts/json/hashfair-nft-lvl7.json'
import Lottie from 'react-lottie';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper.css';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const OurNFT = () => {
    const nftTitles = [
        {
            key: nftLvl1,
            value: "BLINKERS"
        },
        {
            key: nftLvl2,
            value: "SLOTTERSPOT"
        },
        {
            key: nftLvl3,
            value: "SPINWIN"
        },
        {
            key: nftLvl4,
            value: "WILDCARD"
        },
        {
            key: nftLvl5,
            value: "THUNDERBIRD"
        },
        {
            key: nftLvl6,
            value: "JACKPOT"
        },
        {
            key: nftLvl7,
            value: "KINGPIN"
        }
    ];
    const swiperRef = React.useRef(null);
    return (
        <>
            {/* NFT section start */}
            <section className="nftMain" id='nft'>
                <div className="container">
                    <div className="section-headline text-center">
                        <h3>Our NFT's</h3>
                        <p>Elevate Your Collection, Embrace the Digital Renaissance: HashFair NFTs Redefine Possibilities!"</p>
                    </div>
                    <div className="popular_gaming_inner">
                        <Swiper
                            className="reasultPageSwiper"
                            loop={true}
                            spaceBetween={35} // Add the desired gap value here (20px)
                            slidesPerView={3} // Show 3 items per view on desktop
                            navigation={true} // Enable navigation arrows
                            // pagination={{ clickable: true }} // Enable pagination bullets
                            autoplay={{ delay: 3000 }} // Set autoplay delay to 3000ms (3 seconds)
                            onSwiper={(swiper) => (swiperRef.current = swiper)}
                            breakpoints={{
                                // When window width is >= 768px (desktop)
                                768: {
                                    slidesPerView: 3,
                                },
                                // When window width is < 768px (mobile)
                                0: {
                                    slidesPerView: 1,
                                },
                            }}
                        >
                            {nftTitles.map((item, index) => (
                                <SwiperSlide key={index}>
                                    {/* <img src={item} alt={`Slide ${index + 1}`} /> */}
                                    <div className="popular_gaming_thumb">
                                        <a href="#!">
                                            <span className='nftboxtitle'>{`${item.value}`}</span>
                                            <LazyLoad className="nftsvideo" once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                                <Lottie
                                                    options={{
                                                        loop: true,
                                                        autoplay: true,
                                                        animationData: item.key,
                                                        rendererSettings: {
                                                            preserveAspectRatio: 'xMidYMid slice'
                                                        }
                                                    }}
                                                />
                                                {/* <ReactPlayer
                                                    className='react-player nftsvideo'
                                                    url={item.key}
                                                    width='100%'
                                                    height='100%'
                                                    muted
                                                    loop={true}
                                                    playing={true}
                                                    playsinline={true}
                                                    autoPlay={true}
                                                /> */}
                                            </LazyLoad>
                                        </a>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
            {/* NFT section end */}
        </>
    )
}

export default OurNFT