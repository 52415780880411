import React from 'react';
// import logo from '../../Assets/images/logo.json'
// import ReactPlayer from 'react-player';
import LazyLoad from 'react-lazyload';
import Lottie from 'react-lottie';
import logoData from '../../Assets/images/json/logo.json';
const Header = () => {
    const logoVideo = {
        loop: true,
        autoplay: true,
        animationData: logoData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            <header>
                <a href="/">

                    <LazyLoad className="logoMainHead" once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                        <Lottie
                            options={logoVideo}
                            width="160px"
                            height="160px"
                        />
                        {/* <ReactPlayer
                            url={logo}
                            width='100%'
                            height='100%'
                            muted
                            loop
                            playing={true}
                            playsinline={true}
                            autoPlay={true}
                        /> */}
                    </LazyLoad>
                </a>
            </header>
        </>
    )
}

export default Header