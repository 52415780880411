import React from 'react';
// import aboutvideo from '../../Assets/images/about-banner.mp4'
// import ReactPlayer from 'react-player';
import LazyLoad from 'react-lazyload';
import Lottie from 'react-lottie';
import animationData from '../../Assets/images/json/aboutbox.json';
const About = () => {
    // const videoRef = useRef(null);

    // useEffect(() => {
    //     // On initial load, check if the video is muted and attempt to autoplay
    //     if (videoRef.current && videoRef.current.muted) {
    //         videoRef.current.play();
    //     }
    // }, []);
    const aboutVideo = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            {/* About Start */}
            <section className="aboutMain pt-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="aboutInner mb-3">
                                <h2 className='mb-3'>About the <span>HashFair</span></h2>
                                <strong className='d-block mb-4 font-normal'>HashFair is the best gaming studio for all gamer.</strong>
                                <p>HashFair is a groundbreaking online casino platform that employs blockchain technology to create a decentralized gaming atmosphere. The platform stands out by leveraging transaction hashes from the blockchain to determine game results, guaranteeing complete transparency and trustworthiness in the gaming experience.</p>
                            </div>
                            <div className="btgGrp">
                                <a className="rainbow-btn m-0 bgblack" href="#!" target="_blank" rel="noreferrer"><span>Go Dashboard</span></a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <LazyLoad className="aboutVideo" once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                <Lottie
                                    options={aboutVideo}
                                />
                            </LazyLoad>
                            {/* <LazyLoad once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                <ReactPlayer
                                    className='aboutVideo'
                                    url={aboutvideo}
                                    width='100%'
                                    height='100%'
                                    muted={true}
                                    loop={true}
                                    playing={true}
                                    playsinline={true}
                                    playsInline={true}
                                    autoPlay={true}
                                />
                            </LazyLoad> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* About End */}
        </>
    )
}

export default About