import React, { useEffect } from 'react';
// import bannerBg from '../../Assets/images/rotate.webp'
import LazyLoad from 'react-lazyload';
import icon1 from '../../Assets/images/1.webp'
import icon2 from '../../Assets/images/2.webp'
import icon3 from '../../Assets/images/3.webp'
import icon4 from '../../Assets/images/4.webp'
import Lottie from 'react-lottie';
import bannerBg from '../../Assets/images/json/bannerBg.json';
const Banner = () => {
    useEffect(() => {
        const handleMouseMove = (e) => {
            const bg = document.querySelector('.banenrAnimicon');
            const windowWidth = window.innerWidth / 5;
            const windowHeight = window.innerHeight / 5;
            const mouseX = e.clientX / windowWidth;
            const mouseY = e.clientY / windowHeight;
            bg.style.transform = `translate3d(-${mouseX}%, -${mouseY}%, 0)`;
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    const bannerBgOptions = {
        loop: true,
        autoplay: true,
        animationData: bannerBg,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>

            <section className="topBanner">
                {/* <img className="bannerBg" src={bannerBg} alt="bannerBg" /> */}
                <LazyLoad className="bannerBg" once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                    <Lottie
                        options={bannerBgOptions}
                    />
                </LazyLoad>
                <div className="banenrAnimicon">
                    <img className="icon4" src={icon4} alt="bannerBg" />
                    <img className="icon3" src={icon3} alt="bannerBg" />
                    <img className="icon2" src={icon2} alt="bannerBg" />
                    <img className="icon1" src={icon1} alt="bannerBg" />
                </div>
                <div className="container">
                    <div className="topBannerInner text-center">
                        <h1>Welcome to HashFair reliability  online gaming</h1>
                        <p>Welcome to HashFair, a cutting-edge online gaming platform that operates on decentralization and DAO principles. Our platform ensures fairness and transparency in every game by linking outcomes to blockchain transaction hashes. Experience the revolutionary world of HashFair, where the results of every roll, spin, or deal are calculated using hash values, providing unmatched security and reliability for online gaming.</p>
                        <div className="btgGrp">
                            <a className="rainbow-btn" href="https://plan.hashfair.network/register" target="_blank" rel="noreferrer"><span>Register</span></a>
                            <a className="rainbow-btn" href="https://plan.hashfair.network/login" target="_blank" rel="noreferrer"><span>Login to Dashboard</span></a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner