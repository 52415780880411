import React from 'react';
import Lottie from 'react-lottie';
import LazyLoad from 'react-lazyload';
import faqIcon from '../../Assets/images/json/faq.json';
import Faq from "react-faq-component";
const data = {
    rows: [
        {
            title: "What is HashFair Games?",
            content: `HashFair Games is the first decentralized casino that operates with a decentralized dealer. It offers a unique gambling experience where the results are calculated using a secure and transparent algorithm based on hash functions.`,
        },
        {
            title: "How does the decentralized dealer work?",
            content: `In HashFair Games, the dealer's role is decentralized, meaning that the outcome of each game is not determined by a centralized entity but by a verifiable algorithm based on hash functions. This ensures fairness and eliminates any possibility of manipulation or bias..`,
        },
        {
            title: "Which wallet should I use to participate in HashFair Games?",
            content: `HashFair Games is compatible with various crypto wallets. However, we recommend using Trust Wallet or Metamask Wallet for the best user experience and seamless integration with the platform.`,
        },
        {
            title: "What are the coins in HashFair Games?",
            content: `HashFair Games operates with two types of coins. The first coin is playable known as HUSD, and it is used to participate in the games within the platform. The second coin is won as rewards known as HFG when you win games, and it is cashable, meaning you can exchange it for other cryptocurrencies or fiat currency.`,
        },
        {
            title: "How are the games based on the DAO concept?",
            content: `The games in HashFair Games are built on the DAO (Decentralized Autonomous Organization) concept. This means that the platform operates in a decentralized manner, governed by smart contracts and the consensus of the community. The decisions and rules of the platform are transparent, and the community members have a say in its operation.`,
        },
        {
            title: "How are profits shared within the community?",
            content: `As HashFair Games is community-based, the profits generated by the platform are shared among the participants. The exact profit-sharing mechanism may vary, but typically a portion of the profits is distributed among token holders or active participants in the community. This creates an incentive for users to contribute to the platform's growth and success.`,
        },
        {
            title: "What is the minimum starting package for HashFair?",
            content: `The minimum starting package for HashFair is $10.`,
        },
        {
            title: "How can I buy an NFT on HashFair?",
            content: `To buy an NFT on HashFair, you need to claim a voucher. Please note that vouchers can only be used with HUSD tokens.`,
        },
        {
            title: "When will the 50% of invested amount in play tokens be distributed?",
            content: `The distribution of 50% of the invested amount in play tokens will take place within 30 days.`,
        },
    ],
};

const styles = {
    bgColor: 'transparent',
    titleTextColor: "#9b9b9b",
    rowTitleColor: "#ffffff",
    rowContentColor: '#9f9f9f',
    arrowColor: "#ffffff",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
const Faqs = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: faqIcon,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            <section className="faqMain">
                <div className="container">
                    <div className="section-headline text-center">
                        <h3>FAQ's</h3>
                        <p>Got Questions? We've Got Answers! Explore the HashFair FAQ and Discover a World of Clarity.</p>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <LazyLoad className="faqImg" once placeholder={<svg className="loaderAnim" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0"><path fill="#ffffff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg>}>
                                <Lottie
                                    options={defaultOptions}
                                    height={400}
                                    width={400}
                                />
                            </LazyLoad>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <Faq
                                data={data}
                                styles={styles}
                                config={config}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faqs